import {createSlice} from '@reduxjs/toolkit';

const initialState = JSON.parse(localStorage.getItem('cart')) || {
  cartIsOpen: false,
  orderId: null,
  products: [],
  totalQuantity: 0,
  totalPrice: 0,
  totalPriceWithShipping: 0,
  shippingPrice: 0,
  checkout: {
    information: {},
    shipping: {},
    payment: {}
  },
  paymentMethods: [],
  shippingMethods: []
};

initialState.cartIsOpen = false;

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addProduct(state, action) {
      const existingProductIndex = state.products.findIndex(
        (product) => product.id === action.payload.id
      );

      if (existingProductIndex !== -1) {
        state.products[existingProductIndex].quantity +=
          action.payload.quantity;
      } else {
        state.products.push(action.payload);
      }

      state.totalPrice += action.payload.quantity * (action.payload.price ?? action.payload.oldPrice)
      state.totalPriceWithShipping = state.totalPrice + state.shippingPrice
      state.totalQuantity += action.payload.quantity;

      saveCartToLocalStorage(state)
    },
    setCartIsOpen (state, action) {
      state.cartIsOpen = action.payload;
    },

    increaseProductCount(state, action) {
      const productId = action.payload;
      const productToUpdate = state.products.find((product) => product.id === productId);

      if (productToUpdate) {
        productToUpdate.quantity += 1;
        state.totalQuantity += 1;
        state.totalPrice += (productToUpdate.price ?? productToUpdate.oldPrice)
        state.totalPriceWithShipping = state.totalPrice + state.shippingPrice
      }

      saveCartToLocalStorage(state)
    },

    decreaseProductCount(state, action) {
      const productId = action.payload;
      const productToUpdate = state.products.find((product) => product.id === productId);
      if (productToUpdate && productToUpdate.quantity > 1) {
        productToUpdate.quantity -= 1;
      } else if (productToUpdate && productToUpdate.quantity === 1) {
        const productIndex = state.products.findIndex((product) => product.id === productId);
        state.products.splice(productIndex, 1);
      }
      state.totalPrice -= (productToUpdate.price ?? productToUpdate.oldPrice)
      state.totalPriceWithShipping = state.totalPrice + state.shippingPrice
      state.totalQuantity -= 1;

      saveCartToLocalStorage(state)
    },

    removeProduct(state, action) {
      const productIdToRemove = action.payload;
      const productIndexToRemove = state.products.findIndex(
        (product) => product.id === productIdToRemove
      );

      if (productIndexToRemove !== -1) {
        const removedProduct = state.products[productIndexToRemove];
        state.totalQuantity -= removedProduct.quantity;
        state.totalPrice -= (removedProduct.price ?? removedProduct.oldPrice) * removedProduct.quantity;

        state.totalPriceWithShipping = state.totalPrice + state.shippingPrice
        state.products.splice(productIndexToRemove, 1);
      }

      saveCartToLocalStorage(state)
    },
    updateCheckoutDetails(state, action) {
      const {type, details} = action.payload;
      if (type === 'information') {
        state.checkout.information = { ...state.checkout.information, ...details };
      } else if (type === 'shipping') {
        state.checkout.shipping = { ...state.checkout.shipping, ...details };
      } else if (type === 'payment') {
        state.checkout.payment = { ...state.checkout.payment, ...details };
      }

      saveCartToLocalStorage(state)
    },
    updateShippingPrice(state, action) {
      state.shippingPrice = action.payload
      state.totalPriceWithShipping = state.totalPrice + action.payload

      saveCartToLocalStorage(state)
    },
    updatePickupAddress(state, action) {
      state.checkout.shipping.pickupAddress = action.payload;

      saveCartToLocalStorage(state)
    },
    updateStockQuantity(state, action) {
      const { id, newStockQuantities } = action.payload;
      const productToUpdate = state.products.find((product) => product.id === id);
      if (productToUpdate) {
        productToUpdate.stock_quantity = newStockQuantities;
      }

      saveCartToLocalStorage(state)
    },
    setOrderId(state, action) {
      state.orderId = action.payload;

      saveCartToLocalStorage(state)
    },
    addPaymentMethods(state, action) {
      state.paymentMethods = action.payload;

      saveCartToLocalStorage(state)
    },
    addShippingMethods(state, action) {
      state.shippingMethods = action.payload;

      saveCartToLocalStorage(state)
    },
    clearCartStore(state){
      state.orderId = null;
      state.products = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.totalPriceWithShipping = 0;
      state.shippingPrice = 0;
      state.checkout = {
        information: {},
        shipping: [],
        payment: []
      };
      state.paymentMethods = [];
      state.shippingMethods = []

      saveCartToLocalStorage(state)
    },
    setCartStore(state, action) {
      localStorage.removeItem('cart');
      action.payload.cartIsOpen = false
      return { ...state, ...action.payload };
    },
  },
});

const selectProductById = (productId) => (state) =>
  state.cart.products.find((product) => product.id === productId);

const saveCartToLocalStorage = (state) => {
  localStorage.setItem('cart', JSON.stringify(state));
};

export const {
  addProduct,
  setCartIsOpen,
  increaseProductCount,
  decreaseProductCount,
  removeProduct,
  updateCheckoutDetails,
  updateShippingPrice,
  updatePickupAddress,
  updateStockQuantity,
  setOrderId,
  addPaymentMethods,
  addShippingMethods,
  setCartStore,
  clearCartStore
} = cartSlice.actions;


export {selectProductById};

export default cartSlice.reducer;
