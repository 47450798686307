import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import SliderPagination from './SliderPagination';

export default function Slider (props) {
  if (!props.slides) return null;

  return <div className={`relative${props.className ? ` ${props.className}` : ''}`}>
    <Swiper
      key={props.slides.length}
      modules={[Navigation]}
      slidesPerView="auto"
      slidesPerGroup={1}
      spaceBetween={16}
      breakpoints={{
        576: {
          slidesPerView: 'auto',
          spaceBetween: 24,
          slidesPerGroup: 1,
        },
      }}
      centeredSlides={false}
      navigation={true}
      watchSlidesProgress
    >
      {props.slides.map((slide, index) => (
        <SwiperSlide key={index} className={props.slideClass || ''}>
          {slide}
        </SwiperSlide>
      ))}
      {!props.noPagination && (
        <SliderPagination count={props.slides?.length} color={props.bulletColor} />
      )}
    </Swiper>
  </div>
}
