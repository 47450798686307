import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { Footer, Header } from 'sections';
import { Breadcrumbs } from 'ui';
import ErrorBoundary from 'components/ErrorBoundary';
import PageSeo from 'components/PageSeo';
import StickyContactUs from 'components/StickyContactUs';

export default function DefaultLayout (props) {
  return (
    <>
      <div className="min-h-screen">
        <PageSeo />
        <Header />
        <main className="mb-20 md:mb-50">
          <Breadcrumbs withContainer />
          <ErrorBoundary>
            {props.children}
          </ErrorBoundary>
        </main>
      </div>
      <Footer />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <StickyContactUs />
    </>
  )
}
