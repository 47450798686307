import React, { useEffect, useState } from 'react';
import { useSwiper } from 'swiper/react';

export default function SliderPagination ({ count, color = 'default' }) {
  const swiper = useSwiper();
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const updateActiveIndex = () => {
      setActiveIndex(swiper.activeIndex);
    };

    swiper.on('slideChange', updateActiveIndex);

    return () => {
      swiper.off('slideChange', updateActiveIndex);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swiper]);

  const bullets = [];
  for (let i = 0; i < count; i++) {
    bullets.push(
      <span
        className={`slider-pagination__bullet slider-pagination__bullet--${color}${i === activeIndex ? ' is-active' : ''}`}
        key={i}
        onClick={() => swiper.slideTo(i)}
      ></span>
    );
  }

  return (
    <div className="slider-pagination">
      {bullets}
    </div>
  )
}
