import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filterIsOpen: false,
  filteredCampers: [],
  activeBodyTypes: [],
  activeBrands: [],
  seatsCount: [],
  bedsCount: [],
};

const campersSlice = createSlice({
  name: 'campers',
  initialState,
  reducers: {
    setFilterIsOpen(state, action) {
      state.filterIsOpen = action.payload;
    },
    setFilteredCampers(state, action) {
      state.filteredCampers = action.payload;
    },
    setActiveBodyTypes(state, action) {
      state.activeBodyTypes = action.payload;
    },
    setActiveBrands(state, action) {
      state.activeBrands = action.payload;
    },
    setSeatsCount(state, action) {
      state.seatsCount = action.payload;
    },
    setBedsCount(state, action) {
      state.bedsCount = action.payload;
    },
    clearFilters(state) {
      state.activeBodyTypes = [];
      state.activeBrands = [];
      state.seatsCount = [];
      state.bedsCount = [];
    },
  },
});

export const {
  setFilterIsOpen,
  setFilteredCampers,
  setActiveBodyTypes,
  setActiveBrands,
  setSeatsCount,
  setBedsCount,
  clearFilters,
} = campersSlice.actions;

export default campersSlice.reducer;
