import React, { useEffect, useState } from 'react'
import ImagePlaceholder from 'img/ImagePlaceholder.jpg'
import { useTranslatableContent } from 'hooks';
import LazyLoad from 'react-lazy-load';

function isVideo (src) {
  return /\.(mp4|webm|ogg)$/i.test(src)
}

function MaybeHasThumbnail ({ thumbnail, children, className }) {
  return thumbnail
    ? <div
      className={className || 'h-full w-auto bg-cover bg-center'}
      style={thumbnail ? { backgroundImage: `url(${thumbnail})` } : {}}
    >
      {children}
    </div>
    : <>{children}</>
}

function Image({alt, className, content, thumbnailClasses, noThumbnail = false}, ref) {
  const [imageUrl, setImageUrl] = useState(content ? (content.src || content.url) : ImagePlaceholder)
  const maybeTranslate = useTranslatableContent()

  useEffect(() => {
    if (content?.webp) {
      setImageUrl(content.webp)
    } else if (content?.src) {
      setImageUrl(content.src)
    } else if (content?.url) {
      setImageUrl(content.url)
    } else {
      setImageUrl(ImagePlaceholder)
    }
  }, [content])

  const onError = () => {
    setImageUrl(ImagePlaceholder)
  }

  if (isVideo(imageUrl)) {
    return (
      <LazyLoad>
        <video
          className={`video${className ? ' ' + className : ''}`}
          ref={ref}
          autoPlay
          muted
          playsInline
          alt={maybeTranslate(content?.alt)}
          title={maybeTranslate(content?.title)}
        >
          <source src={imageUrl} type={`video/${imageUrl.split('.').pop()}`} />
        </video>
      </LazyLoad>
    )
  }

  return (
    <MaybeHasThumbnail thumbnail={noThumbnail ? null : content?.thumbnail} className={thumbnailClasses}>
      <LazyLoad once>
        <img
          className={`image${className ? ' ' + className : ''}`}
          onError={onError}
          ref={ref}
          src={imageUrl}
          alt={maybeTranslate(content?.alt) || alt}
          title={maybeTranslate(content?.title)}
          width={content?.width}
          height={content?.height}
        />
      </LazyLoad>
    </MaybeHasThumbnail>
  )
}

export default React.forwardRef(Image)
