import { NavLink } from 'react-router-dom';

const Menu = (props) => {
    const { className, menuList } = props;

    const classes = `menu${className ? ' ' + className : ''}`;

    const getPath = (menuPoint) => {
        return menuPoint.data?.custom_path ? menuPoint.data.custom_path : menuPoint.value.path
    }

    return (
        <nav className={classes}>
            <ul className="menu__list">
                {menuList?.map((point) => {
                    return (
                    <li key={point.id} className="menu__item">
                        <NavLink
                            to={getPath(point)}
                            target={point.target}
                            className="menu__link"
                        >
                            {point.name}
                        </NavLink>
                    </li>
                )})}
            </ul>
        </nav>
    );
};

export default Menu;
